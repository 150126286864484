import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const RoadmapContainer = styled.section`
    background: ${color.red};
    width: 100%;
    padding-top: 115px;

    @media ${breakpoints.xsmd} {
        padding-top: 42px;
    }

    p {
        font-size: 30px;
        margin: 54px 0 20px;

        @media ${breakpoints.xsmd} {
            font-size: 18px;
            margin: 20px 0 25px;
        }
    }
`;

export const Title = styled.h2`
    font-size: 104px;
    color: ${color.bgColor};


    @media ${breakpoints.xsmd} {
        font-size: 40px;
    }
`;

export const RoadmapContent = styled.div`
    > p {
        @media ${breakpoints.xs} {
            margin-bottom: 100px;
        }
    }
`;

export const ContentBlocks = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 205px;

    @media ${breakpoints.lg} {
        padding-top: 150px;
    }

    @media ${breakpoints.md} {
        padding-top: 99px;
    }

    @media ${breakpoints.xs} {
        flex-direction: column;
        padding-top: 0;
    }

    + p {
        padding: 25px 0 110px;

        @media ${breakpoints.md} {
            padding: 45px 0 80px;
        }

        @media ${breakpoints.xs} {
            padding: 0 0 70px;
            margin: -50px 0 0;
        }
    }
`;

export const Block = styled.div`
    width: 364px;
    height: 300px;
    background: ${color.beige};
    padding: 107px 48px 75px;
    color: ${color.bgColor};
    border-radius: 40px;
    border: 4px solid ${color.bgColor};
    position: relative;

    @media ${breakpoints.lg} {
        width: 27vw;
        height: 18vw;
        padding: 8vw 3vw 5vw;
    }

    @media ${breakpoints.md} {
        width: 27vw;
        height: 18vw;
        padding: 8vw 3vw 5vw;
    }

    @media ${breakpoints.xs} {
        width: 100%;
        height: 220px;
        padding: 70px 40px 55px;
        margin-bottom: 100px;
    }
    
    img {
        position: absolute;
        top: -56%;
        left: 50%;
        transform: translateX(-50%);

        @media ${breakpoints.lg} {
            width: 34vw;
            top: -71%;
        }

        @media ${breakpoints.md} {
            width: 29vw;
            top: -59%;
        }

        @media ${breakpoints.xs} {
            width: 252px;
            top: -94px;
        }
    }

    p {
        margin: 0;
        text-align: center;
        font-size: 30px;

        @media ${breakpoints.lg} {
            font-size: 1.8vw;
        }

        @media ${breakpoints.md} {
            font-size: 1.8vw;
        }

        @media ${breakpoints.xs} {
            font-size: 22px;
        }
    }
`;

