export const breakpoints = {
    desktopOnly: 'only screen and (min-width: 992px)',
    belowDesktopOnly: 'only screen and (max-width: 1279px)',
    desktop: 'only screen and (min-width: 1280px)',
    lg: 'only screen and (min-width: 992px) and (max-width: 1279px)',
    md: 'only screen and (min-width: 768px) and (max-width: 991px)',
    xs: '(max-width: 767px)',
    aboveXs: '(min-width: 767px)',
    xsmd: 'only screen and (max-width: 991px)',
    sm: 'only screen and (min-width: 575px) and (max-width: 767px)',
    xxs: 'only screen and (min-width:320px) and (max-width: 375px)',
    xsip: 'only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2)',
    xsip5: 'only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2)',
    xsipx: 'only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3)',
    xsipxpro: 'only screen and and (min-device-width: 414px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3)',
    xsipplus: 'only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3)',
    xsipxr: 'only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2)',
    xsandroid: 'only screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm)'
};

export const color = {
    bgColor: '#282B2E',
    red: '#C74B49',
    redGrey: '#BEACAD',
    beige: '#E9E6D4',
}