import React from 'react';
import { RoadmapContainer, RoadmapContent, Title, ContentBlocks, Block } from './styles';

const Roadmap = () => {

  return (
    <RoadmapContainer id="roadmap">

      <RoadmapContent className='main-container'>
        <Title className='title'>THE Roadmap</Title>
        <p>
        Chris J. "Mohawk" Reeds is a successful personal branding expert, with over <b>105,000 followers</b> on LinkedIn.
        <br></br><br></br>
        Our aim is to make MohawkNFT a powerhouse brand for entrepreneurs, businesses, and individuals! Building such a brand takes time and we will smash the competition with Mohawk Cyborgs by:
        </p>

        <ContentBlocks>
          <Block>
            <img src='/images/hair-1.png' alt='' />
            <p>Launching MohawkNFT to the community</p>
          </Block>

          <Block>
            <img src='/images/hair-2.png' alt='' />
            <p>Getting verified on NFT marketplaces</p>
          </Block>

          <Block>
            <img src='/images/hair-3.png' alt='' />
            <p>Opening the augmentation room to members</p>
          </Block>
        </ContentBlocks>

        <ContentBlocks>
          <Block>
            <img src='/images/hair-4.png' alt='' />
            <p>Activating exclusive merch drops to members</p>
          </Block>

          <Block>
            <img src='/images/hair-5.png' alt='' />
            <p>IRL annual MohawkNFT party, it’ll be crazy!</p>
          </Block>

          <Block>
            <img src='/images/hair-6.png' alt='' />
            <p>Collaborating with other successful NFT brands</p>
          </Block>
        </ContentBlocks>

        <p>
        After we have completed the above, we will collaborate with the community to explore gaming, metaverse experiences, and other assets!
        </p>

      </RoadmapContent>
      
    </RoadmapContainer>
  );
};

export default Roadmap;