import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const TeamContainer = styled.section`
    position: relative;
    width: 100%;
    background: url('/images/bg-team.png') no-repeat;
    padding-bottom: 150px;

    background-size: 187%;
    background-position-x: center;
    background-position-y: top;

    @media ${breakpoints.belowDesktopOnly} {
        background-size: 225%;
        background-position-y: 65%;
        padding-bottom: 8vw;
    }

    @media ${breakpoints.xs} {
        background: none;
        padding-bottom: 70px;
    }
`;

export const TeamContent = styled.div`
    padding-top: 130px;

    @media ${breakpoints.xs} {
        padding-top: 80px;
    }

    p {
        font-size: 30px;
        margin: 54px 0 40px;

        @media ${breakpoints.xsmd} {
            font-size: 18px;
            margin: 20px 0 25px;
        }

        a {
            color: ${color.beige};
            text-decoration: underline;
        }
    }
`;

export const Title = styled.h2`
    font-size: 104px;
    line-height: 0.7;
    color: ${color.beige};

    @media ${breakpoints.xsmd} {
        font-size: 40px;
    }
`;

export const TeamDetailsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 75px;

    @media ${breakpoints.belowDesktopOnly} {
        padding-bottom: 4vw;
    }

    @media ${breakpoints.xs} {
        flex-direction: column;
        padding-bottom: 0;
    }
`;

export const TeamDetail = styled.div`
    color: ${color.bgColor};
    width: 270px;
    height: 560px;
    background: ${color.beige};
    text-align: center;
    margin: 0 22px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${breakpoints.belowDesktopOnly} {
        width: 18vw;
        height: 37vw;
        margin: 0 1.5vw;
    }

    @media ${breakpoints.xs} {
        margin: 0 0 50px;
        height: auto;
        width: 100%;
        padding-bottom: 40px;
    }
    
    img { 
        margin-top: -60px;
        width: 65%;

        @media ${breakpoints.belowDesktopOnly} {
            margin-top: -3vw;
        }

        @media ${breakpoints.xs} {
            width: 60%;
        }
    }

    h3 {
        font-size: 56px;
        line-height: 0.9;
        padding: 30px 0 15px;

        @media ${breakpoints.belowDesktopOnly} {
            font-size: 3.5vw;
            padding: 20px 0 10px;
        }

        @media ${breakpoints.xs} {
            font-size: 62px;
        }
    }

    p {
        font-size: 30px;
        margin: 0;
        padding: 0;

        @media ${breakpoints.belowDesktopOnly} {
            font-size: 1.6vw;
        }

        @media ${breakpoints.xs} {
            font-size: 33px;
        }
    }
`;

export const Barcode = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;

    @media ${breakpoints.belowDesktopOnly} {
        margin-top: -1vw;
    }

    svg {
        width: 80px;

        @media ${breakpoints.belowDesktopOnly} {
            width: 35px;
        }

        @media ${breakpoints.xs} {
            width: 85px;
        }
    }
`;

export const Code = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    text-transform: uppercase;

    @media ${breakpoints.belowDesktopOnly} {
        margin-left: 1vw;
    }

    p {
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        margin: 0;
        padding: 0;

        @media ${breakpoints.belowDesktopOnly} {
            font-size: 1.3vw;
        }

        @media ${breakpoints.xs} {
            font-size: 22px;
        }
    }
`;

export const DetailCEO = styled.div`
    width: 888px;
    background: ${color.beige};
    color: ${color.bgColor};
    margin: 0 auto;
    padding: 34px 38px;

    @media ${breakpoints.belowDesktopOnly} {
        width: 62vw;
        padding: 2vw 2.5vw;
    }

    @media ${breakpoints.xs} {
        padding: 20px 26px 34px;
        width: 100%;
    }

    img {
        width: 100%;
    }

    > p {
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'Montserrat', sans-serif;
        margin-top: 10px;

        @media ${breakpoints.xs} {
            font-size: 30px;
        }
    }
`;

export const Detail = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 35px;
    align-items: center;

    @media ${breakpoints.belowDesktopOnly} {
        margin-top: 2vw;
    }

    @media ${breakpoints.xs} {
        margin-top: 34px;
        flex-direction: column;
    }

    h3 {
        font-size: 52px;
        margin-left: 24px;
        margin-top: -10px;

        @media ${breakpoints.belowDesktopOnly} {
            font-size: 3.5vw;
            margin-top: -0.6vw;
        }

        @media ${breakpoints.xs} {
            margin-top: 25px;
            font-size: 62px;
            text-align: center;
            margin-left: 0;
        }

        a {
            color: ${color.bgColor};
            text-decoration: underline;
        }
    }
`;