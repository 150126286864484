import React from 'react';
import { FlashContainer, Title, Carousel } from './styles';
import Slider from "react-slick";

const Flash = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <FlashContainer className='main-container'>
      <Title className='title'>news flash!</Title>
      <p>Mohawk Cyborgs have escaped from a top-secret augmentation lab in Singapore! Help Chris capture these Mohawk Cyborgs with MohawkNFTs. Then bring them back to the augmentation room!</p>

      <Carousel>
        <Slider {...settings}>
          <div>
            <img src='/images/story-1.png' alt='' />
          </div>
          <div>
            <img src='/images/story-2.jpg' alt='' />
          </div>
          <div>
            <img src='/images/story-3.jpg' alt='' />
          </div>
          <div>
            <img src='/images/story-4.jpg' alt='' />
          </div>
          <div>
            <img src='/images/story-5.jpg' alt='' />
          </div>
        </Slider>
      </Carousel>
      
    </FlashContainer>
  );
};

export default Flash;