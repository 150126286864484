import React from 'react';
import { Modal, ModalContent, BtnClose } from './styles';

const ModalSuccess = () => {
    const close = () => {
      modalClose();
      hideSuccess();
    }

    const modalClose = () => {
      const body = document.querySelector('body');
      body.classList.remove('modal-open');
    }

    const hideSuccess = () => {
      const loading = document.querySelector('.modal-success');
      loading.style.display = 'none';
    }

    return (
        <Modal className='modal-success'>
          <ModalContent>
            <BtnClose onClick={() => { close() }}>X</BtnClose>
            <img className='symbol' src='/images/img-success.png' alt='' />
          <p className='title'>Congratulations! Transaction success</p>
          </ModalContent>
        </Modal>
    );
};

export default ModalSuccess;