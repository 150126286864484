import React from 'react';
import { ethers } from 'ethers';
import { BuyButton } from './styles';
import detectEthereumProvider from '@metamask/detect-provider';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

const BuyBtn = () => {
  
  const openMetaMaskUrl = async (e) => {
    const provider = await detectEthereumProvider();
    if (provider) {
      e.preventDefault();
      buyNow();
    }
  }

  const buyNow = async () => {
    
    const provider = await detectEthereumProvider();

    if (provider) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log('connected');
        execute();
        showLoading();
        modalOpen();
      } catch (error) {
        hideLoading();
        showFailedNoParam();
        console.log(error);
      }
    } else {

      console.log('not connect, show pop up with please install metaMask');
      showInstall();
      modalOpen();

      // if (isMobile) {
      //   // openMetaMaskUrl('https://metamask.app.link/dapp/mohawknft.io');

      // } else {
        
      // }
    }
  }

  const execute = async () => {
    // run SMART CONTRACT LOCALLY: > smart-contract: yarn hardhat node
    // TUTORIAL : https://www.youtube.com/watch?v=pdsYCkUWrgQ
    // =======================================================================================//
    // address - SimpleStorage deployed at 0x5FbDB2315678afecb367f032d93F642f64180aa3
    // contract ABI - deployment > simpleStorage.json
    // function
    // Node Connection === Metamask

    const contractAddress = "0x10Bf91DA91542a2c3e68e9C0f093f2A01Ee599d7";
    const abi = [
      {
        "inputs": [
          {
            "internalType": "string",
            "name": "_baseTokenURI",
            "type": "string"
          },
          {
            "internalType": "uint256",
            "name": "_totalSupply",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "approved",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "Approval",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "operator",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
          }
        ],
        "name": "ApprovalForAll",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "Transfer",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "MINT_PRICE",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "RELEASED_MINT_PRICE",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "TOTAL_SUPPLY",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "earlyMintMohawk",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "finalized",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "getApproved",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "operator",
            "type": "address"
          }
        ],
        "name": "isApprovedForAll",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "maxSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "mintMohawk",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "name",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "ownerOf",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "releaseDate",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "bytes",
            "name": "_data",
            "type": "bytes"
          }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "operator",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
          }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "string",
            "name": "_baseTokenURI",
            "type": "string"
          }
        ],
        "name": "setBaseTokenURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bytes4",
            "name": "interfaceId",
            "type": "bytes4"
          }
        ],
        "name": "supportsInterface",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "symbol",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "tokenURI",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bool",
            "name": "newFinal",
            "type": "bool"
          }
        ],
        "name": "updateParams",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address payable",
            "name": "payee",
            "type": "address"
          }
        ],
        "name": "withdrawAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    ];
    
    
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const chainid = await signer.getChainId();
    if (chainid !== 1) {
      wrongChain();
      console.log('plz connect to the ethereum network');

    } else {
      const contract = new ethers.Contract(contractAddress, abi, signer);
      const maxLength = await (await contract.maxSupply()).toString();
      let nftTotalLength = await (await contract.totalSupply()).toString();
      try {
        // const earlyMint = await contract.stoppedEarlyMint();
        const time = (await provider.getBlock(this)).timestamp;
        const earlyMintEnded = (time <= await contract.releaseDate()) ? false : true;
//        console.log(time);
//        console.log(await (await contract.releaseDate()).toString());
        console.log(earlyMintEnded);
        console.log('length of history of mint order: ', nftTotalLength);

        let tx;
        if (earlyMintEnded === false) {
          tx = await contract.earlyMintMohawk({ value: ethers.utils.parseEther("0.15") });
        } else {
          tx = await contract.mintMohawk({ value: ethers.utils.parseEther("0.2") });
        }
          const tx_wait = await tx.wait();
          const tx_status = tx_wait.status;
        
          if (tx_status === 1) {
            console.log('success executed');
            console.log('mohawk nft number: ', tx_wait.events[0].args[2].toString());

            modalOpen();
            showSuccess(tx_wait.events[0].args[2].toString());
            hideLoading();
          } else {
            console.log(nftTotalLength);
            console.log(maxLength);
            hideLoading();
            showFailed(nftTotalLength,maxLength);
          }
          // IF SHOWING ERROR GO TO METAMASK ACCOUNT > Advanced > reset Account

      } catch (error) {
          console.log(error);
          hideLoading();
          showFailed(nftTotalLength,maxLength);
      }
    }
  }

  const modalOpen = () => {
    const body = document.querySelector('body');
    body.classList.add('modal-open');
  }

  const showLoading = () => {
    const loading = document.querySelector('.modal-loading');
    loading.style.display = 'block';
  }

  const hideLoading = () => {
    const loading = document.querySelector('.modal-loading');
    loading.style.display = 'none';
  }

  const showFailed = (length,maxLength) => {
    const modal = document.querySelector('.modal-failed');
    const content = document.querySelector('.modal-failed .title');
    content.innerHTML = '';

    if (length === maxLength) {
      content.innerHTML = 'SOLD OUT!';
    } else {
      content.innerHTML = 'TRANSACTION FAILED!! <br> PLEASE TRY AGAIN LATER';
    }

    modal.style.display = 'block';
  }

    const showFailedNoParam = () => {
    const modal = document.querySelector('.modal-failed');
    const content = document.querySelector('.modal-failed .title');
    content.innerHTML = '';
      content.innerHTML = 'TRANSACTION FAILED!! <br> PLEASE TRY AGAIN LATER';

    modal.style.display = 'block';
  }

  const showInstall = () => {
    const loading = document.querySelector('.modal-install');
    loading.style.display = 'block';
  }

  const showSuccess = (string) => {
    const modal = document.querySelector('.modal-success');
    const content = document.querySelector('.modal-success .title');

    content.innerHTML = "";
    content.innerHTML = "Congratulations! Transaction success, you've just obtained Mohawk #" + string;

    modal.style.display = 'block';
  }

  const wrongChain = () => {
    const loading = document.querySelector('.modal-wrong-chain');
    loading.style.display = 'block';
  }

  return (
    <>
      {isMobile
      ? 
      <MobileView>
        <BuyButton onClick={(e) => { openMetaMaskUrl(e) }}>
          <a class="buyBtnMobile" href="https://metamask.app.link/dapp/mohawknft.io" target="_blank" rel="noreferrer">own yours today</a>
        </BuyButton>
      </MobileView>
      : 
      <BrowserView>
        <BuyButton onClick={() => { buyNow() }}>
        own yours today
        </BuyButton>
      </BrowserView>
      }
    </>
  );
};

export default BuyBtn;