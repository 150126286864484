import { createGlobalStyle } from "styled-components";
import { breakpoints } from '../theme/index';

const GlobalStyle = createGlobalStyle`

.hide {
    display: none;

    &--desktop {
        @media ${breakpoints.desktop} {
            display: none;
        }
    }

    &--desktopOnly {
        @media ${breakpoints.desktopOnly} {
            display: none;
        }
    }

    &--belowDesktopOnly  {
        @media ${breakpoints.belowDesktopOnly} {
            display: none;
        }
    }

    &--xsmd {
        @media ${breakpoints.xsmd} {
            display: none;
        }
    }

    &--xs {
        @media ${breakpoints.xs} {
            display: none;
        }
    }

    &--aboveXs {
        @media ${breakpoints.aboveXs} {
            display: none;
        }
    }

    &--md {
        @media ${breakpoints.md} {
            display: none;
        }
    }

    &--lg {
        @media ${breakpoints.lg} {
            display: none;
        }
    }
}

.modal-open {
    height: 100vh;
    overflow: hidden;
}

.main-container {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;

    @media ${breakpoints.xsmd} {
        padding: 0 34px;
        max-width: 100%;
    }
}

.title {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
    line-height: 1;
}
`;

export default GlobalStyle;