import React from 'react';
import { Routes, Route } from "react-router-dom";

import GlobalStyle from './theme/GlobalStyle';
import Header from './components/common/Header';
import { Symbol } from './components/common/Header/styles';

import Home from './components/section/Home';
import Flash from './components/section/Flash';
import Collection from './components/section/Collection';
import CollectionDetails from './components/section/CollectionDetails';
import Roadmap from './components/section/Roadmap';
import FollowUs from './components/section/FollowUs';
import Team from './components/section/Team';
import Footer from './components/common/Footer';
import Augmentation from './components/section/Augmentation';
import Faq from './components/section/Faq';
import Loading from './components/common/ModalLoading';
import ModalFailed from './components/common/ModalFailed';
import ModalInstall from './components/common/ModalInstall';
import ModalSuccess from './components/common/ModalSuccess';
import ModalWrongChain from './components/common/ModalWrongChain';
import PlanAugmentation from './components/section/PlannedAugmentation';
import Tnc from './components/section/Tnc';

function App() {
  return (
    <>
    <GlobalStyle />
    <Header />
    <Symbol className='hide--belowDesktopOnly'>
      <a href='#header'>
        <img className='logo' src="/images/svg/symbol.svg" alt='' />
      </a>
    </Symbol>
    <Routes>
      <Route path="/" 
        element={
          <>
            <Loading />
            <ModalFailed />
            <ModalInstall />
            <ModalSuccess />
            <ModalWrongChain />

            <Home />
            <Flash />
            <Collection />
            <CollectionDetails />
            <Roadmap />
            <FollowUs />
            <Faq />
            <Team />
          </>
        } 
      />
      <Route path="augmentation-room" element={
        <>
          <Augmentation />
          <PlanAugmentation />
          <FollowUs />
        </>
      } />
      <Route path="tnc" element={
        <>
          <Tnc />
        </>
      } />
      </Routes> 
    <Footer />
    </>
  );
};

export default App;
