import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';
import { MenuContainer, MenuList } from '../Menu/styles';
import  { SocialList } from '../Social/styles';

export const HeaderContainer = styled.div`
    position: relative;
    z-index: 3;
    font-weight: 600;
`;

export const HeaderDesktop = styled.div`
    position: absolute;
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 90px 40px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @media ${breakpoints.belowDesktopOnly} {
        display: none;
    }

    @media ${breakpoints.xs} {
        justify-content: center;
        padding: 38px 0 0;
    }
    
    > div {
        display: flex;
        align-items: center;
    }

    .icon {
        a {
            color: ${color.beige};;
            font-size: 22px;
            white-space: nowrap;
        }
    
        svg {
            width: 42px;
            fill: ${color.beige};
        }
    }

    ${SocialList} {
        display: flex;
        align-items: center;
    
        @media ${breakpoints.belowDesktopOnly} {
            display: none;
        }
    
        li {
            padding-left: 20px;
            padding-right: 0;

            &.btn-guide {
                padding-right: 20px;
            }

            &.disable {
                opacity: 0.7;
                cursor: pointer;
                pointer-events: none;
            }
        }
    
        a {
            color: ${color.beige};;
            font-size: 22px;
            white-space: nowrap;
        }
    
        svg {
            width: 42px;
            fill: ${color.beige};
        }
    }
`;

export const HeaderLeft = styled.div`

    ${MenuContainer} {
        display: block;
        position: static;
        padding: 0;
    }

    ${MenuList} {
        display: flex;
        align-items: center;

        li {
            margin-left: 0;
            margin-right: 34px;
            font-size: 22px;
        }
    }
`;

export const BurgerBar = styled.div`
    display: none;
    width: 40px;
    height: 14px;
    position: fixed;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    margin: 34px 0 0 34px;
    z-index: 3;

    @media ${breakpoints.belowDesktopOnly} {
        display: block;
    }

    &.open {
        span {
            &:nth-child(1) {
                transform: rotate(30deg);
                top: 0;

                @media ${breakpoints.xs} {
                    left: 0;
                }
            }

            &:nth-child(2) {
                width: 0%;
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-30deg);
                top: 20px;

                @media ${breakpoints.xs} {
                    left: 0;
                }
            }
        }
    }

    span {
        display: block;
        position: absolute;
        height: 3px;
        opacity: 1;
        right: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        background: ${color.beige};
        width: 100%;

        @media ${breakpoints.xs} {
        }

        &:nth-child(1) {
            top: 0px;
            transform-origin: left center;
        }

        &:nth-child(2) {
            top: 6px;
            transform-origin: left center;
        }

        &:nth-child(3) {
            top: 12px;
            transform-origin: left center;
        }
    }
`;

export const Symbol = styled.div`
    position: fixed;
    margin-left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    top: 65px;

    .logo {
        width: 94px;
        
        @media ${breakpoints.xsmd} {
            display: none;
        }
    }
`;