import styled from 'styled-components';
import { color } from '../../../theme/index';

export const SocialList = styled.ul`
    li {
        padding: 0 10px;
    }

    svg {
        width: 36px;
        fill: ${color.beige};
    }
`;