import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const HomeContainer = styled.section`
    position: relative;
    width: 100%;
    text-align: center;
    background: url('/images/bg.png') no-repeat;
    background-position-x: center;

    @media ${breakpoints.md} {
        background-position-y: center;
        background-size: 165%;

        h2 {
            font-size: 18px;
            padding: 0 40px;
        }
    }

    @media ${breakpoints.xs} {
        background-position-y: top;
        background-size: 200%;

        h2 {
            font-size: 18px;
            padding: 0 40px;
        }
    }
`;

export const CloudOne = styled.div`
    background: url(/images/cloud-1.png) no-repeat;
    background-position-y: top;
    background-size: 100%;
    width: 1042px;
    height: 614px;
    position: absolute;
    left: 50%;
    top: -10%;
    transform: translateX(-8%);
    animation: cloudOneAnimation 20s ease-in-out infinite;

    @media only screen and (min-width: 992px) and (max-width: 1399px) {
        width: 46vw;
        height: 45vw;
        top: -10%;
    }

    @media ${breakpoints.belowDesktopOnly} {
        width: 36vw;
        height: 30vh;
        top: -10%;
    }

    @media ${breakpoints.xs} {
        width: 47vw;
        top: 3%;
    }

    @keyframes cloudOneAnimation {
        0% {
            transform: translateX(-31%);
        }

        50% {
            transform: translateX(-8%);
        }
        
        100% {
            transform: translateX(-31%);
        }
    }
`;

export const CloudTwo = styled.div`
    background: url(/images/cloud-2.png) no-repeat;
    background-position-y: top;
    background-size: 100%;
    width: 968px;
    height: 394px;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-111%);
    animation: cloudTwoAnimation 30s ease-in-out infinite;

    @media only screen and (min-width: 992px) and (max-width: 1399px) {
        width: 46vw;
        height: 31vh;
    }

    @media ${breakpoints.belowDesktopOnly} {
        width: 36vw;
        height: 22vh;
    }

    @media ${breakpoints.xs} {
        width: 66vw;
        height: 18vh;
        top: 20%;
    }

    @keyframes cloudTwoAnimation {
        0% {
            transform: translateX(-111%);
        }

        50% {
            transform: translateX(-90%);
        }
        
        100% {
            transform: translateX(-111%);
        }
    }
`;

export const CloudThree = styled.div`
    background: url(/images/cloud-3.png) no-repeat;
    background-position-y: top;
    background-size: 80%;
    width: 968px;
    height: 394px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-2%);
    animation: cloudThreeAnimation 15s ease-in-out infinite;

    @media only screen and (min-width: 992px) and (max-width: 1399px) {
        width: 46vw;
        height: 31vh;
    }

    @media ${breakpoints.belowDesktopOnly} {
        width: 36vw;
        height: 22vh;
    }

    @media ${breakpoints.xs} {
        width: 54vw;
        top: 53%;
    }

    @keyframes cloudThreeAnimation {
        0% {
            transform: translateX(-2%);
        }

        50% {
            transform: translateX(-14%);
        }
        
        100% {
            transform: translateX(-2%);
        }
    }
`;

export const CloudFour = styled.div`
    background: url(/images/cloud-4.png) no-repeat;
    background-position-y: top;
    background-size: 80%;
    width: 1072px;
    height: 502px;
    position: absolute;
    left: 50%;
    bottom: 0%;
    transform: translateX(-68%%);
    animation: cloudFourAnimation 25s ease-in-out infinite;

    @media only screen and (min-width: 992px) and (max-width: 1399px) {
        width: 46vw;
        height: 31vh;
    }

    @media ${breakpoints.belowDesktopOnly} {
        width: 36vw;
        height: 22vh;
    }

    @media ${breakpoints.xs} {
        width: 56vw;
        height: 14vh;
        bottom: 15%;
    }

    @keyframes cloudFourAnimation {
        0% {
            transform: translateX(-38%);
        }

        50% {
            transform: translateX(-68%);
        }
        
        100% {
            transform: translateX(-38%);
        }
    }
`;

export const CloudFloat = styled.div`
    animation: float 6s ease-in-out infinite;

    @keyframes float {
        0% {
            shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            // transform: translateY(0%);
        }
        50% {
            shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
            // transform: translateY(-0.5%);
        }
        100% {
            shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            // transform: translateY(0%);
        }
    }
`;

export const HomeContent = styled.div`
    padding: 300px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    z-index: 1;
    position: relative;

    @media ${breakpoints.belowDesktopOnly} {
        padding: 80px 0 0;
    }

    @media ${breakpoints.md} {
        max-width: 50%;
    }

    @media ${breakpoints.xs} {
        padding: 80px 34px 0;
    }

    .logo {
        @media ${breakpoints.xsmd} {
            margin-bottom: 25px;
        }
    }

    .headline {
        margin: 60px 0 80px;
        max-width: 540px;

        @media only screen and (min-width: 992px) and (max-width: 1399px) {
            margin: 40px 0 60px;
        }
    }
`;

export const EarlyBirdContent = styled.div`
    h3 {
        color: ${color.red};
        font-size: 55px;
        margin-bottom: 25px;
        font-weight: 100;

        @media ${breakpoints.xsmd} {
            font-size: 37px;
            margin-top: 40px;
            margin-bottom: 12px;
        }

        @media ${breakpoints.xs} {
            margin-top: 0;
        }
    }
`;

export const DetailRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    width: 460px;
    margin: 0 auto 6px;
    font-size: 30px;

    @media only screen and (min-width: 992px) and (max-width: 1399px) {
        width: 400px;
        font-size: 24px;
    }

    @media ${breakpoints.xsmd} {
        max-width: 275px;
        font-size: 20px;
    }

`;

export const Date = styled.div`

`;

export const Price = styled.div`
    width: 152px;
    text-align: center;
    background: ${color.beige};
    color: ${color.bgColor};
    padding: 8px 0;

    @media ${breakpoints.xsmd} {
        width: 75px;
    }
`;

export const Separator = styled.div`
    margin-top: 140px;
    background: ${color.beige};
    position: relative;
    z-index: 1;

    @media ${breakpoints.md} {
        margin-top: 50px;
    }

    @media ${breakpoints.xs} {
        margin-top: 46px;
    }

    img {
        width: 100%;
        max-width: 2613px;
    }
`;
