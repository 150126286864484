import React from 'react';
import { HomeContainer, HomeContent, EarlyBirdContent, DetailRow, Date, Price, Separator, CloudOne, CloudFloat, CloudTwo, CloudThree, CloudFour } from './styles';
import BuyBtn from '../../common/BuyBtn/index'

const Home = () => {

    return (
      <HomeContainer>
        <CloudFloat>
          <CloudOne>
          </CloudOne>
        </CloudFloat>

        <CloudFloat>
          <CloudTwo>
          </CloudTwo>
        </CloudFloat>

        <CloudFloat>
          <CloudThree>
          </CloudThree>
        </CloudFloat>

        <CloudFloat>
          <CloudFour>
          </CloudFour>
        </CloudFloat>

        <HomeContent>
          <img className='hide--xsmd logo' src='/images/logo.png' alt='' />
          <img className='hide--desktopOnly logo' src='/images/logo-sm.png' alt='' />
          <img className='headline hide--xsmd' src='/images/svg/headline.svg' alt='' />
          <h2 className='hide--desktopOnly'>NFT series from Chris J "Mohawk" Reed for entreprenuers, businesses and individuals</h2>

          <BuyBtn />

          <EarlyBirdContent>
            <h3 className='title'>LAUNCH weekend</h3>
            <DetailRow>
              <Date>27th, 28th, 29th May</Date>
              <Price className='title'>0.15 ETH</Price>
            </DetailRow>
            <DetailRow>
              <Date>Thereafter</Date>
              <Price className='title'>0.20 ETH</Price>
            </DetailRow>
          </EarlyBirdContent>

        </HomeContent>

        <Separator>
          <img className='hide--xs' src='/images/separator.png' alt='' />
          <img className='hide--aboveXs' src='/images/separator-sm.png' alt='' />
        </Separator>

      </HomeContainer>
    );
};

export default Home;