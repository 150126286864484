import React, { useState } from 'react';
import { MenuContainer, MenuList } from './styles';
import Social from '../Social/index';

const Menu = ({isMenuOpened, closeMenu}) => {

    const MENU_LIST = [
        {
            text: "Collection",
            active: true,
        },
        {
            text: "Roadmap",
            active: false,
        },
        {
            active: false,
            text: "Team",
        },
        {
            active: false,
            text: "FAQ",
        },
    ];

    const [menuList] = useState(MENU_LIST);

    const updateMenu = (e) =>{
        document.querySelector('.menu-list li.active').classList.remove("active");
        setTimeout(() => {
            e.target.parentElement.classList.add('active');
        },100);
        
        closeMenu();
    };

    return (
        <MenuContainer className={`${isMenuOpened ? 'is-open' : ''}`}>
            <MenuList className="menu-list">
                <li className="icon">
                    <a href='/'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.22 42.02"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M21.61,0C9.67,0,0,9.41,0,21S9.67,42,21.61,42s21.61-9.41,21.61-21S33.54,0,21.61,0Zm9.75,33H24.25V26.2H18.91V33H11.85V23.38l9.77-9.77,9.74,9.77ZM32.1,23,21.52,12.1,11.11,23,7.73,21.25,21.61,7.65l13.88,13.6Z"/></g></g></svg>
                    </a>
                </li>
                {
                    menuList.map((menu) => {
                        return (
                            <li key={menu.text} onClick={(e) => updateMenu(e)} className={`${menu.active ? 'active' : ''}`}>
                                <a className='' href={`/#${menu.text.toLowerCase().replace(/\s/g, '')}`}>{menu.text}</a>
                            </li>
                        );
                    })
                }
                <li className='hide--desktop'>
                    <a href='/augmentation-room'>Augmentation Room</a>
                </li>
            </MenuList>
            <Social />
        </MenuContainer>
    );
};

export default Menu;