import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const FooterContainer = styled.section`
    background: ${color.red};
    width: 100%;
    padding-top: 44px;
    padding-bottom: 60px;

    @media ${breakpoints.belowDesktopOnly} {
        padding-top: 3vw;
        padding-bottom: 4vw;
    }

    @media ${breakpoints.xs} {
        padding-bottom: 30px;
    }

    p {
        font-size: 30px;

        @media ${breakpoints.belowDesktopOnly} {
            font-size: 2vw;
        }

        @media ${breakpoints.xs} {
            font-size: 20px;
        }
    }
`;

export const FooterContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 25px;

    @media ${breakpoints.belowDesktopOnly} {
        font-size: 1.8vw;
    }

    @media ${breakpoints.xs} {
        font-size: 16px;
        flex-direction: column;
    }
`;

export const ContractAdd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom:  60px;

    @media ${breakpoints.belowDesktopOnly} {
        padding-bottom: 4vw;
    }

    @media ${breakpoints.xs} {
        flex-direction: column;
        text-align: center;
        padding-bottom: 40px;
    }

    p {
        word-wrap: break-word;
        hyphens: auto;
    }

    img {
        width: 70px;
        margin-right: 30px;

        @media ${breakpoints.xs} {
            margin-right: 0;
        }
    }
`;

export const Copyright = styled.div`
    @media ${breakpoints.xs} {
        margin-bottom: 20px;
    }
`;

export const Button = styled.a`
    color: ${color.beige};
`;

