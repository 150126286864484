import React from 'react';
import { FaqContainer, FaqContent, Title, FaqTabContent } from './styles';

const Faq = () => {

  return (
    <FaqContainer id="faq">

      <FaqContent className='main-container'>
        
        <div>
          <Title className='title'>FAQ</Title>

          <FaqTabContent>
            <div >
              <p className='question'>Will there be an allowlist?</p>
              <p>No, everyone will be treated equally, MohawkNFTs will be available to everyone at the same time through the <a href='https://www.mohawknft.io/'>www.mohawknft.io</a> website.</p>

              <p className='question'>What are the maximum mints per wallet?</p>
              <p>There is no maximum, however you can only buy 1 NFT per transaction, this is to give everyone a chance of buying a MohawkNFT at launch.</p>
              
              <p className='question'>What is the launch weekend?</p>
              <p>The launch weekend is when we launch MohawkNFT, to celebrate, all MohawkNFTs will be available at a special launch weekend price. After the launch weekend the price will revert to the normal price.</p>

              <p className='question'>What intellectual property rights do MohawkNFT holders receive?</p>
              <p>Owners of MohawkNFTs will have full commercial art rights for the MohawkNFT they own. However, they will have no rights in relation to the MohawkNFT brand. More information can be found in the terms and conditions at the bottom of this page.</p>

              <p className='question'>Will I be able to sell my MohawkNFT?</p>
              <p>With so many benefits, why would you want to sell your MohawkNFT? However, if you really do, then yes you can sell them on marketplaces like Opensea.</p>

              <p className='question'>What are the secondary royalties?</p>
              <p>All MohawkNFTs will have a 5% royalty on secondary sales.</p>

              <p className='question'>Will my MohawkNFT be revealed right away?</p>
              <p>Yes, after you buy your MohawkNFT, you will be able to see your MohawkNFT (give or take a few minutes for Ethereum network processing).</p>
              
              <p className='question'>What crypto wallet do you support?</p>
              <p>Metamask.</p>

              <p className='question'>What blockchain are MohawkNFTs minted onto?</p>
              <p>Ethereum blockchain</p>
            </div>
          </FaqTabContent>
        </div>
        
      </FaqContent>
      
    </FaqContainer>
  );
};

export default Faq;