import React from 'react';
import { FollowUsContainer, FollowUsContent, Title } from './styles';
import Social from '../../common/Social/index';

const FollowUs = () => {

  return (
    <FollowUsContainer >

      <FollowUsContent className='main-container'>
        
        <div>
          <Title className='title'>follow us</Title>
          <p>
          Stay up to date on how the <br></br>
          5,000 MohawkNFT are doing!
          </p>

          <Social></Social>
        </div>

        <img src='/images/img-follow-us.png' alt='' />
        
      </FollowUsContent>
      
    </FollowUsContainer>
  );
};

export default FollowUs;