import React from 'react';
import { PlanAugmentationContainer, PlanAugmentationContent, Title, BlockTitle, ContentBlocks, Block, RevealDate } from './styles';

const PlanAugmentation = () => {

  return (
    <PlanAugmentationContainer id="PlanAugmentation">

      <PlanAugmentationContent className='main-container'>
        <Title className='title'>planned augmentations</Title>
        
        <ContentBlocks>
          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>SIGNED BOOK</BlockTitle>
            <p>Signed "How to become a LinkedIn Rock Star" book </p>
            <RevealDate className='title'>REVEALS 01/07/2022</RevealDate>
          </Block>

          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>linkedin PROFILE</BlockTitle>
            <p>Comprehensive analysis from Chris J. "Mohawk" Reed on your LinkedIn profile and how it can be improved, emailed directly to you  </p>
            <RevealDate className='title'>REVEALS 01/07/2022</RevealDate>
          </Block>
        </ContentBlocks>

        <ContentBlocks>
          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>PERSONAL BRANDING</BlockTitle>
            <p>One hour 1-1 consultation with Chris J. "Mohawk" Reed worth USD$1.5k on how to improve your personal branding on LinkedIn </p>
            <RevealDate className='title'>REVEALS 01/07/2022</RevealDate>
          </Block>

          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>1 TO 1</BlockTitle>
            <p>Direct communications with Chris J. "Mohawk" Reed where you can send your content or profile for personalised analysis and feedback </p>
            <RevealDate className='title'>REVEALS 01/07/2022</RevealDate>
          </Block>
        </ContentBlocks>

        <ContentBlocks>
          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>LUNCH OR DINNER</BlockTitle>
            <p>IRL fully paid for lunch or dinner with Chris J. "Mohawk" Reed at the WooBar in Sentosa for 2 hours, ask anything session</p>
            <RevealDate className='title'>REVEALS 01/08/2022</RevealDate>
          </Block>

          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>EXCLUSIVE AMA</BlockTitle>
            <p>Free Q&amp;A with Chris J. "Mohawk" Reed and your team to answer any questions about LinkedIn and personal branding worth USD$4,950  </p>
            <RevealDate className='title'>REVEALS 01/08/2022</RevealDate>
          </Block>
        </ContentBlocks>

        <ContentBlocks>
          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>MARKETING</BlockTitle>
            <p>20% off any of the Black Marketing rates - saving you thousands of dollars</p>
            <RevealDate className='title'>REVEALS 01/08/2022</RevealDate>
          </Block>

          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>MASTERCLASS</BlockTitle>
            <p>20% of any Chris J. "Mohawk" Reed LinkedIn Masterclasses - saving you thousands of dollars</p>
            <RevealDate className='title'>REVEALS 01/08/2022</RevealDate>
          </Block>
        </ContentBlocks>

        <ContentBlocks>
          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>SECRET FIRESIDE</BlockTitle>
            <p>Exclusive invite to a virtual 4 panel fireside chat with 4 LinkedIn rockstars on entrepreneurship and utilising LinkedIn to grow your personal brand</p>
            <RevealDate className='title'>REVEALS 01/09/2022</RevealDate>
          </Block>

          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>MOHAWK PARTY</BlockTitle>
            <p>Annual "rockstar" party held at a different location every year for 100 guests and influential entrepreneurs and business people </p>
            <RevealDate className='title'>REVEALS 01/09/2022</RevealDate>
          </Block>
        </ContentBlocks>

        <ContentBlocks className='last-row'>
          <Block>
            <img src='/images/img-collab.png' alt='' />
            <BlockTitle className='title'>BEST SELLING AUTHOR</BlockTitle>
            <p>Become a best selling book author  </p>
            <RevealDate className='title'>REVEALS 01/09/2022</RevealDate>
          </Block>


        </ContentBlocks>

        <Title className='title'>further augmentations</Title>

        <p>
        Discussions are in progress to bring many more exciting augmentations to MohawkNFT holders. All designed to help you get ahead in this super competitive world.
        <br></br><br></br>
        This includes collaborations with other companies for exclusive benefits, physical merchandise (such as clothing and accessories) and other high end NFT projects.
        <br></br><br></br>
        This is just the beginning of something special...
        </p>

      </PlanAugmentationContent>
      
    </PlanAugmentationContainer>
  );
};

export default PlanAugmentation;