import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const PlanAugmentationContainer = styled.section`
    background: ${color.red};
    width: 100%;
    padding-top: 115px;
    padding-bottom: 160px;

    @media ${breakpoints.xsmd} {
        padding-top: 42px;
        padding-bottom: 50px;
    }

    p {
        font-size: 30px;
        margin: 54px 0 20px;

        @media ${breakpoints.xsmd} {
            font-size: 18px;
            margin: 20px 0 25px;
        }
    }
`;

export const Title = styled.h2`
    font-size: 104px;
    color: ${color.bgColor};


    @media ${breakpoints.md} {
        font-size: 40px;
    }

    @media ${breakpoints.xs} {
        font-size: 30px;
        margin-bottom: 30px;
    }
`;

export const BlockTitle = styled.h3`
    font-size: 56px;
    margin: 38px 0 30px;

    @media ${breakpoints.lg} {
        font-size: 4.5vw;
        margin: 25px 0 10px;
    }

    @media ${breakpoints.md} {
        font-size: 4.5vw;
        margin: 25px 0 10px;
    }

    @media ${breakpoints.xs} {
        font-size: 33px;
        margin: 20px 0 17px;
    }
`;

export const RevealDate = styled.span`
    background: ${color.bgColor};
    color: ${color.red};
    font-size: 40px;
    padding: 20px 25px;
    display: flex;
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    @media ${breakpoints.lg} {
        font-size: 2.5vw;
        bottom: 5vw;
    }

    @media ${breakpoints.md} {
        font-size: 2.5vw;
        bottom: 4vw;
    }

    @media ${breakpoints.xs} {
        font-size: 23px;
        bottom: 27px;
        padding: 10px 18px;
    }
`;

export const PlanAugmentationContent = styled.div`
    > p {
        @media ${breakpoints.xs} {
            margin-bottom: 20px;
        }
    }
`;

export const ContentBlocks = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 67px 75px 0;

    @media ${breakpoints.lg} {
        padding: 3vw 0 0;
    }

    @media ${breakpoints.md} {
        padding: 3vw 0 0;
    }

    @media ${breakpoints.xs} {
        flex-direction: column;
        padding: 0;
    }

    &.last-row {
        margin-bottom: 148px;

        @media ${breakpoints.md} {
            margin-bottom: 50px;
        }

        @media ${breakpoints.xs} {
            margin-bottom: 40px;
        }
    }

    + p {
        padding: 25px 0 110px;

        @media ${breakpoints.md} {
            padding: 45px 0 80px;
        }

        @media ${breakpoints.xs} {
            padding: 0 0 70px;
            margin: -50px 0 0;
        }
    }
`;

export const Block = styled.div`
    width: 492px;
    height: 700px;
    background: ${color.beige};
    padding: 45px 30px;
    color: ${color.bgColor};
    border-radius: 50px;
    position: relative;
    border: 10px solid ${color.bgColor};
    text-align: center;

    @media ${breakpoints.lg} {
        width: 43vw;
        height: 62vw;
        padding: 5vw 3vw 5vw;
    }

    @media ${breakpoints.md} {
        width: 43vw;
        height: 61vw;
        padding: 4vw 3vw 4vw;
    }

    @media ${breakpoints.xs} {
        border: 4px solid ${color.bgColor};
        width: 100%;
        height: 435px;
        padding: 27px 33px;
        margin: 0 0 40px;
    }

    img {
        @media ${breakpoints.xs} {
            width: 90px;
        }
    }

    p {
        margin: 0;
        text-align: center;
        font-size: 30px;

        @media ${breakpoints.lg} {
            font-size: 2.2vw;
        }

        @media ${breakpoints.md} {
            font-size: 1.8vw;
        }

        @media ${breakpoints.xs} {
            font-size: 18px;
        }
    }
`;

