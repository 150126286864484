import React from 'react';
import { FooterContainer, FooterContent, ContractAdd, Copyright, Button } from './styles';

const Footer = () => {

  return (
    <FooterContainer >

      <div className='main-container'>
        <ContractAdd>
          <img src='/images/svg/symbol.svg' alt='' />
          <p>Smart contract address: <br className='hide--aboveXs' /><b>0x10Bf91DA91542a2c3e68e9C0f093f2A01Ee599d7</b></p>
        </ContractAdd>

        <FooterContent>
          <Copyright>MohawkNFT. All rights Reserved.</Copyright>
          <Button href='/tnc'>Terms &amp; Conditions</Button>
        </FooterContent>
      </div>

    </FooterContainer>
  );
};

export default Footer;