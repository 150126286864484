import React from 'react';
import { AugmentationContainer, AugmentationContent, CloudOne, CloudFloat, CloudTwo, CloudThree, CloudFour } from './styles';

const Augmentation = () => {

    return (
      <AugmentationContainer>

        <CloudFloat>
          <CloudOne>
          </CloudOne>
        </CloudFloat>

        <CloudFloat>
          <CloudTwo>
          </CloudTwo>
        </CloudFloat>

        <CloudFloat>
          <CloudThree>
          </CloudThree>
        </CloudFloat>

        <CloudFloat>
          <CloudFour>
          </CloudFour>
        </CloudFloat>

        <AugmentationContent>
          <img className='logo' src='/images/logo-benefit.png' alt='' />
          {/* <img className='headline hide--xsmd' src='/images/svg/headline.svg' alt='' /> */}
          <h2 className='title'>Augmentation <br></br>room</h2>
          <p>
            <b>OPENING SOON - UNDER REPAIR!</b><br></br>
            Check your MohawkNFT and its augmentations to see what 
            special personal branding benefits, collaborations, merchandise, experiences  
            and NFT drops you have earned!
          </p>
        </AugmentationContent>

      </AugmentationContainer>
    );
};

export default Augmentation;