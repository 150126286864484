import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const FaqContainer = styled.section`
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: ${color.red};

    @media ${breakpoints.md} {
        padding-top: 7vw;
        padding-bottom: 7vw;
    }

    @media ${breakpoints.xs} {
        padding-top: 45px;
        padding-bottom: 45px;
    }
`;

export const Title = styled.h2`
    font-size: 104px;
    line-height: 0.7;
    color: ${color.bgColor};

    @media ${breakpoints.xsmd} {
        font-size: 40px;
    }

`;

export const FaqContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${breakpoints.xs} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const FaqTab = styled.ul`
    font-size: 18px;
    display: flex;
    margin-top: 30px;

    @media ${breakpoints.xs} {
        margin: 0;
    }
`;

export const FaqItem = styled.li`
    padding: 15px 40px;
    border-radius: 20px 20px 0 0;
    font-weight: bold;
    cursor: pointer;

    @media ${breakpoints.xs} {
        font-size: 12px;
        text-align: center;
        padding: 15px 10px;
    }

    @media ${breakpoints.md} {
        font-size: 16px;
        padding: 15px 22px;
    }

    &.active {
        background: ${color.red};
        color: ${color.bgColor};
    }
`;

export const FaqTabContent = styled.div`
    font-size: 20px;
    padding: 30px 0;

    @media ${breakpoints.md} {
        padding: 0;
    }

    @media ${breakpoints.xs} {
        height: 55vh;
    }

    > div {
        padding-right: 20px;

        @media ${breakpoints.xs} {
            overflow: auto;
            height: 100%;        }

        a {
            color: ${color.beige};
            text-decoration: underline;
        }

        /* Scrollbar Styling */
        ::-webkit-scrollbar {
            width: 6px;
        }
        
        ::-webkit-scrollbar-track {
            background-color: ${color.redGrey};
            border-radius: 10px;
            border: 2px solid ${color.redGrey};
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background: ${color.bgColor}; 
        }
    }

    p {
        margin: 0;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        
        &.question {
            margin: 30px 0 0;
            color: ${color.bgColor};
            font-weight: 600;

            &.no-margin {
                margin: 0;
            }
        }
    }
`;