import React from 'react';
import { Modal, ModalContent, BtnClose } from './styles';

const ModalFailed = () => {
    const close = () => {
      modalClose();
      hideFailed();
    }

    const modalClose = () => {
      const body = document.querySelector('body');
      body.classList.remove('modal-open');
    }

    const hideFailed = () => {
      const loading = document.querySelector('.modal-failed');
      loading.style.display = 'none';
    }

    return (
        <Modal className='modal-failed'>
          <ModalContent>
            <BtnClose onClick={() => { close() }}>X</BtnClose>
            <img className='symbol' src='/images/img-failed.png' alt='' />
            <p className='title'>TRANSACTION FAILED!! <br></br>PLEASE TRY AGAIN LATER</p>
          </ModalContent>
        </Modal>
    );
};

export default ModalFailed;