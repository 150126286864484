import React from 'react';
import { Modal, ModalContent, BtnClose } from './styles';

const ModalWrongChain = () => {
    const close = () => {
      modalClose();
      hideSuccess();
    }

    const modalClose = () => {
      const body = document.querySelector('body');
      body.classList.remove('modal-open');
    }

    const hideSuccess = () => {
      const loading = document.querySelector('.modal-wrong-chain');
      loading.style.display = 'none';
    }

    return (
        <Modal className='modal-wrong-chain'>
          <ModalContent>
            <BtnClose onClick={() => { close() }}>X</BtnClose>

            <img className='symbol' src='/images/img-failed.png' alt='' />
            <p className='title'>WRONG CHAIN! <br></br>Ensure you are connected to the Rinkeby network on Metamask</p>
          </ModalContent>
        </Modal>
    );
};

export default ModalWrongChain;