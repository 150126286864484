import React from 'react';
import { CollectionDetailsContainer, Title, DetailsContent, DetailsItem, DetailsCopy, Timeline, Row } from './styles';

const CollectionDetails = () => {

  return (
    <CollectionDetailsContainer>
      <DetailsContent className='main-container'>

        <Row>
          <DetailsItem>
            <Title className='title'>project size</Title>
            <DetailsCopy>5,000<span>NFTs</span></DetailsCopy>
          </DetailsItem>

          <DetailsItem>
            <Title className='title'>mint price</Title>
            <DetailsCopy>0.15<span>ETH</span></DetailsCopy>
            <Timeline>(launch weekend)</Timeline>
          </DetailsItem>

          <DetailsItem>
            <Title className='title'>mint price</Title>
            <DetailsCopy>0.20<span>ETH</span></DetailsCopy>
            <Timeline>(post-launch weekend)</Timeline>
          </DetailsItem>
        </Row>
        
      </DetailsContent>      
    </CollectionDetailsContainer>
  );
};

export default CollectionDetails;