import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const FlashContainer = styled.section`
    width: 100%;
    padding: 100px 40px 0;
    max-width: 1280px;

    @media ${breakpoints.xsmd} {
        padding-top: 52px;
    }


    p {
        font-size: 30px;
        margin: 54px 0 80px;

        @media ${breakpoints.xsmd} {
            font-size: 18px;
            margin: 20px 0 25px;
        }
    }
`;

export const Title = styled.h2`
    font-size: 104px;
    color: ${color.red};

    @media ${breakpoints.xsmd} {
        font-size: 40px;
    }
`;

export const Carousel = styled.div`
    .slick-slider {
        max-width: 995px;
        width: 100%;
        margin: 0 auto;

        @media only screen and (min-width: 768px) and (max-width: 1700px) {
            max-width: 600px;
        }

        @media ${breakpoints.md} {
            max-width: 400px;
        }
    }

    .slick-slide img {
        width: 100%;
    }

    .slick-next, .slick-prev {
        z-index: 2;
        width: 70px;
        font-size: initial;
        background-size: cover;
        height: 70px;
        top: 40%;

        @media only screen and (min-width: 768px) and (max-width: 1700px) {
            height: 46px;
            width: 46px;
        }

        @media ${breakpoints.md} {
            height: 30px;
            width: 30px;
        }

        @media ${breakpoints.xs} {
            height: 18px;
            width: 18px;
        }

        &:before {
            display: none;
        }
    }

    .slick-prev {
        left: -35px;
        background: url('/images/svg/chevron-left.svg') no-repeat;

        @media only screen and (min-width: 768px) and (max-width: 1700px) {
            left: -23px;
        }

        @media ${breakpoints.md} {
            left: -15px;
        }

        @media ${breakpoints.xs} {
            left: -9px;
        }
    }

    .slick-next {
        right: -46px;
        background: url('/images/svg/chevron-right.svg') no-repeat;

        @media only screen and (min-width: 768px) and (max-width: 1700px) {
            right: -23px;
        }

        @media ${breakpoints.md} {
            right: -15px;
        }

        @media ${breakpoints.xs} {
            right: -9px;
        }
    }

    .slick-dots {
        bottom: initial;
        margin-top: 52px;
        padding-bottom: 100px;
        position: static;

        @media only screen and (min-width: 768px) and (max-width: 1700px) {
            margin-top: 30px;
            padding-bottom: 80px;
        }

        @media ${breakpoints.xs} {
            margin-top: 16px;
            padding-bottom: 50px;
        }

        li {
            width: 54px;
            height: 54px;
            margin: 0 25px;
            
            @media only screen and (min-width: 768px) and (max-width: 1700px) {
                width: 20px;
                height: 20px;
                margin: 0 15px;
            }

            @media ${breakpoints.xs} {
                width: 13px;
                height: 13px;
                margin: 0 7px;
            }

            &.slick-active {
                button {
                    background: ${color.red};
                }
            }

            button{
                background: ${color.beige};
                border-radius: 50%;
                width: 100%;
                height: 100%;

                &:before {
                    display: none;
                }
            }
        }
    }
`;

