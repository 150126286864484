import React from 'react';
import { TeamContainer, TeamContent, Title, TeamDetailsRow, TeamDetail, Barcode, Code, DetailCEO, Detail } from './styles';

const Team = () => {

    return (
      <TeamContainer id="team">

        <TeamContent className='main-container'>
          <Title className='title'>the team</Title>
          <p>MohawkNFT is designed and created by <a href="https://www.pixoken.com/" target="_blank" rel="noreferrer">Pixoken</a>! For personal branding expert, Chris J "Mohawk" Reed, the only CEO with a Mohawk!</p>
          <TeamDetailsRow>
            <TeamDetail>
              <img src='/images/team-1.png' alt='' />
              <Barcode>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.09 81.51"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M90.5,0h9.31V81.33a9.88,9.88,0,0,1-1,.13c-2.28,0-4.57-.07-6.84,0-1.28.06-1.5-.41-1.5-1.56,0-14.65,0-29.29,0-43.94V0Z"/><path d="M19.41,81.37H10.26V0h9.15Z"/><path d="M60.66,0H69.8V81.37H60.66Z"/><path d="M37.68,81.37H30.49V0h7.19Z"/><path d="M42.46,81.47V.06h7.21c0,.47.08,1,.08,1.43q0,39.24,0,78.46c0,1.17-.25,1.62-1.5,1.55C46.37,81.39,44.45,81.47,42.46,81.47Z"/><path d="M0,0H5.36V81.34H0Z"/><path d="M74.76,0h5.4V81.33h-5.4Z"/><path d="M57.31,81.4h-3V.05h3Z"/><path d="M103.18,0h2.91V81.35h-2.91Z"/><path d="M22.84,0h2.92v81.3H22.84Z"/><path d="M83.05,0h2.66V81.38H83.05Z"/></g></g></svg>
              <Code>
                  <p>C</p>
                  <p>O</p>
                </Code>
              </Barcode>
              <h3 className='title'>captain overwatch</h3>
              <p>Project Manager</p>
            </TeamDetail>

            <TeamDetail>
              <img src='/images/team-2.png' alt='' />
              <Barcode>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.09 81.51"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M90.5,0h9.31V81.33a9.88,9.88,0,0,1-1,.13c-2.28,0-4.57-.07-6.84,0-1.28.06-1.5-.41-1.5-1.56,0-14.65,0-29.29,0-43.94V0Z"/><path d="M19.41,81.37H10.26V0h9.15Z"/><path d="M60.66,0H69.8V81.37H60.66Z"/><path d="M37.68,81.37H30.49V0h7.19Z"/><path d="M42.46,81.47V.06h7.21c0,.47.08,1,.08,1.43q0,39.24,0,78.46c0,1.17-.25,1.62-1.5,1.55C46.37,81.39,44.45,81.47,42.46,81.47Z"/><path d="M0,0H5.36V81.34H0Z"/><path d="M74.76,0h5.4V81.33h-5.4Z"/><path d="M57.31,81.4h-3V.05h3Z"/><path d="M103.18,0h2.91V81.35h-2.91Z"/><path d="M22.84,0h2.92v81.3H22.84Z"/><path d="M83.05,0h2.66V81.38H83.05Z"/></g></g></svg>
              <Code>
                  <p>b</p>
                  <p>s</p>
                </Code>
              </Barcode>
              <h3 className='title'>brush<br></br>stroke</h3>
              <p>Artist</p>
            </TeamDetail>
          </TeamDetailsRow>

          <TeamDetailsRow>
            <TeamDetail>
              <img src='/images/team-3.png' alt='' />
              <Barcode>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.09 81.51"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M90.5,0h9.31V81.33a9.88,9.88,0,0,1-1,.13c-2.28,0-4.57-.07-6.84,0-1.28.06-1.5-.41-1.5-1.56,0-14.65,0-29.29,0-43.94V0Z"/><path d="M19.41,81.37H10.26V0h9.15Z"/><path d="M60.66,0H69.8V81.37H60.66Z"/><path d="M37.68,81.37H30.49V0h7.19Z"/><path d="M42.46,81.47V.06h7.21c0,.47.08,1,.08,1.43q0,39.24,0,78.46c0,1.17-.25,1.62-1.5,1.55C46.37,81.39,44.45,81.47,42.46,81.47Z"/><path d="M0,0H5.36V81.34H0Z"/><path d="M74.76,0h5.4V81.33h-5.4Z"/><path d="M57.31,81.4h-3V.05h3Z"/><path d="M103.18,0h2.91V81.35h-2.91Z"/><path d="M22.84,0h2.92v81.3H22.84Z"/><path d="M83.05,0h2.66V81.38H83.05Z"/></g></g></svg>
              <Code>
                  <p>K</p>
                  <p>W</p>
                </Code>
              </Barcode>
              <h3 className='title'>keyboard warrior</h3>
              <p>Senior Web Engineer</p>
            </TeamDetail>

            <TeamDetail>
              <img src='/images/team-4.png' alt='' />
              <Barcode>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.09 81.51"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M90.5,0h9.31V81.33a9.88,9.88,0,0,1-1,.13c-2.28,0-4.57-.07-6.84,0-1.28.06-1.5-.41-1.5-1.56,0-14.65,0-29.29,0-43.94V0Z"/><path d="M19.41,81.37H10.26V0h9.15Z"/><path d="M60.66,0H69.8V81.37H60.66Z"/><path d="M37.68,81.37H30.49V0h7.19Z"/><path d="M42.46,81.47V.06h7.21c0,.47.08,1,.08,1.43q0,39.24,0,78.46c0,1.17-.25,1.62-1.5,1.55C46.37,81.39,44.45,81.47,42.46,81.47Z"/><path d="M0,0H5.36V81.34H0Z"/><path d="M74.76,0h5.4V81.33h-5.4Z"/><path d="M57.31,81.4h-3V.05h3Z"/><path d="M103.18,0h2.91V81.35h-2.91Z"/><path d="M22.84,0h2.92v81.3H22.84Z"/><path d="M83.05,0h2.66V81.38H83.05Z"/></g></g></svg>
              <Code>
                  <p>c</p>
                  <p>e</p>
                </Code>
              </Barcode>
              <h3 className='title'>contract<br></br>enforcer</h3>
              <p>Smart Contract Developer</p>
            </TeamDetail>

            <TeamDetail>
              <img src='/images/team-5.png' alt='' />
              <Barcode>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.09 81.51"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M90.5,0h9.31V81.33a9.88,9.88,0,0,1-1,.13c-2.28,0-4.57-.07-6.84,0-1.28.06-1.5-.41-1.5-1.56,0-14.65,0-29.29,0-43.94V0Z"/><path d="M19.41,81.37H10.26V0h9.15Z"/><path d="M60.66,0H69.8V81.37H60.66Z"/><path d="M37.68,81.37H30.49V0h7.19Z"/><path d="M42.46,81.47V.06h7.21c0,.47.08,1,.08,1.43q0,39.24,0,78.46c0,1.17-.25,1.62-1.5,1.55C46.37,81.39,44.45,81.47,42.46,81.47Z"/><path d="M0,0H5.36V81.34H0Z"/><path d="M74.76,0h5.4V81.33h-5.4Z"/><path d="M57.31,81.4h-3V.05h3Z"/><path d="M103.18,0h2.91V81.35h-2.91Z"/><path d="M22.84,0h2.92v81.3H22.84Z"/><path d="M83.05,0h2.66V81.38H83.05Z"/></g></g></svg>
              <Code>
                  <p>m</p>
                  <p>e</p>
                </Code>
              </Barcode>
              <h3 className='title'>mouthpiece echo</h3>
              <p>Marketing Expert</p>
            </TeamDetail>

          </TeamDetailsRow>

          <DetailCEO>
            <img className='hide--xs' src='/images/ceo.png' alt='' />
            <img className='hide--aboveXs' src='/images/ceo-sm.png' alt='' />
            <Detail>
              <Barcode>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.09 81.51"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M90.5,0h9.31V81.33a9.88,9.88,0,0,1-1,.13c-2.28,0-4.57-.07-6.84,0-1.28.06-1.5-.41-1.5-1.56,0-14.65,0-29.29,0-43.94V0Z"/><path d="M19.41,81.37H10.26V0h9.15Z"/><path d="M60.66,0H69.8V81.37H60.66Z"/><path d="M37.68,81.37H30.49V0h7.19Z"/><path d="M42.46,81.47V.06h7.21c0,.47.08,1,.08,1.43q0,39.24,0,78.46c0,1.17-.25,1.62-1.5,1.55C46.37,81.39,44.45,81.47,42.46,81.47Z"/><path d="M0,0H5.36V81.34H0Z"/><path d="M74.76,0h5.4V81.33h-5.4Z"/><path d="M57.31,81.4h-3V.05h3Z"/><path d="M103.18,0h2.91V81.35h-2.91Z"/><path d="M22.84,0h2.92v81.3H22.84Z"/><path d="M83.05,0h2.66V81.38H83.05Z"/></g></g></svg>
                <Code>
                  <p>c</p>
                  <p>r</p>
                </Code>
              </Barcode>
              <h3 className='title'><a href='https://sg.linkedin.com/in/b2bsocialmarketing' target="_blank" rel="noreferrer">chris j "mohawk" reed</a></h3>
            </Detail>
            <p>CEO of Black Marketing</p>
          </DetailCEO>

        </TeamContent>

      </TeamContainer>
    );
};

export default Team;