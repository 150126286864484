import React, { useState } from 'react';
import { HeaderContainer, HeaderDesktop, HeaderLeft, BurgerBar } from './styles';
import Menu from '../Menu/index';
import Social from '../Social/index';

const Header = () => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    const toggleMenu = () => {
        document.querySelector('.burger-menu').classList.toggle('open');
        setIsMenuOpened(!isMenuOpened);
    }
    
    const closeMenu = () => {
        setIsMenuOpened(false);
        document.querySelector('.burger-menu').classList.remove('open');
    }
    
    return (
        <HeaderContainer id="header">
            {/* MOBILE */}
            <BurgerBar className="burger-menu" onClick={() => { toggleMenu(); }}>
                <span></span>
                <span></span>
                <span></span>
            </BurgerBar>
            <Menu isMenuOpened={isMenuOpened} closeMenu={closeMenu}></Menu>

            {/* DESKTOP */}
            <HeaderDesktop>
                <HeaderLeft>
                    <Menu isMenuOpened={isMenuOpened} closeMenu={closeMenu}></Menu>
                </HeaderLeft>
                
                <Social />
            </HeaderDesktop>
        </HeaderContainer>
    );
};

export default Header;