import React from 'react';
import { Modal, ModalContent, LoadingWrapper } from './styles';

const Loading = () => {
    
    return (
        <Modal className='modal-loading'>
          <ModalContent>

            <LoadingWrapper>
              <div className='loading-wrapper'>
                <img className='loading-icon' src='/images/loading.png' alt='' />
                <img className='symbol' src='/images/svg/symbol.svg' alt='' />
              </div>
            </LoadingWrapper>
            
            <p className='title'>Your transaction is processing...</p>
          </ModalContent>
        </Modal>
    );
};

export default Loading;