import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const BuyButton = styled.button`
    background: url(/images/btn-bg.png) no-repeat;
    background-size: cover;
    color: ${color.red};
    cursor: pointer;
    border: 0;
    font-weight: 600;
    margin: 0 auto 70px;
    font-size: 37px;
    text-shadow: -1px 0px 1px ${color.red};
    padding: 44px 35px;
    text-transform: uppercase;

    @media ${breakpoints.md} {
        font-size: 27px;
        margin: 40px auto 0;
        padding: 34px 35px;
    }

    @media ${breakpoints.xs} {
        margin: 60px auto 40px;
        padding: 23px 15px;
        width: 100%;
        font-size: 21px;
    }

    a {
        color: ${color.red};
    }
`;