import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const CollectionContainer = styled.section`
    width: 100%;
    padding-top: 0;

    p {
        font-size: 30px;
        margin: 54px 0 80px;

        @media ${breakpoints.xsmd} {
            font-size: 18px;
            margin: 20px 0 25px;
        }
    }
`;

export const Title = styled.h2`
    font-size: 104px;
    color: ${color.red};


    @media ${breakpoints.xsmd} {
        font-size: 40px;
    }
`;

export const CollectionContent = styled.div`
`;

export const SliderLoop = styled.div`
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 110px;

    @media only screen and (min-width: 992px) and (max-width: 1680px) {
        padding-bottom: 70px;
    }

    @media ${breakpoints.xsmd} {
        padding-bottom: 35px;
    }
`;

export const ItemList = styled.ul`
    display: flex;
    animation: scroll 200s linear infinite;
    display: flex;
    width: calc(300px * 48);
    margin-bottom: 30px;

    @media only screen and (min-width: 992px) and (max-width: 1680px) {
        width: calc(200px * 48);
    }

    @media ${breakpoints.xsmd} {
        animation: scroll 80s linear infinite;
        width: calc(115px * 48);
        margin-bottom: 15px;
    }

    &.bottom {
        animation: scrollRight 200s linear infinite;

        @media ${breakpoints.xsmd} {
            animation: scrollRight 80s linear infinite;
        }
    }
    
    // Animation
    @keyframes scroll {
        0% { transform: translateX(0); }
        100% { transform: translateX(-50%)}
    }

    // Animation
    @keyframes scrollRight {
        0% { transform: translateX(-50%)}
        100% { transform: translateX(0)}
    }
`;

export const Item = styled.li`
    width: 204px;
    height: 204px;
    border-radius: 26px;
    display: flex;
    margin-left: 30px;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 20px 0;

    img {
        width: 100%;
    }

    @media only screen and (min-width: 992px) and (max-width: 1680px) {
        width: 170px;
        height: 170px;
        border-radius: 14px;
    }

    @media ${breakpoints.xsmd} {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        padding: 0 8px;
        margin-left: 15px;
    }

    &:nth-child(odd) {
        background: ${color.beige};
    }

    &:nth-child(even) {
        background: ${color.red};
    }
`;