import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const CollectionDetailsContainer = styled.section`
    width: 100%;
    padding-bottom: 50px;
    background: url('/images/img-collection.png') no-repeat;

    background-position-x: right;
    background-position-y: bottom;

    @media only screen and (min-width: 992px) and (max-width: 1439px) {
        background-size: 850px;
        padding-bottom: 100px;
    }

    @media ${breakpoints.md} {
        background-size: 65%;
        padding-bottom: 100px;
    }

    @media ${breakpoints.xs} {
        background-size: 95%;
        padding-bottom: 76vw;
        background-position-x: right;
    }
`;

export const Title = styled.h2`
    font-size: 56px;
    color: ${color.red};

    @media ${breakpoints.xsmd} {
        font-size: 28px;
    }
`;

export const DetailsContent = styled.div``;

export const Row = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

export const DetailsItem = styled.div`
    width: fit-content;
    margin-bottom: 70px;

    @media ${breakpoints.xsmd} {
        margin-bottom: 25px;
    }
`;

export const DetailsCopy = styled.div`
    color: ${color.redGrey};
    line-height: 1;
    font-size: 156px;
    font-weight: 600;

    @media only screen and (min-width: 992px) and (max-width: 1439px) {
        font-size: 120px;
    }

    @media ${breakpoints.xsmd} {
        font-size: 78px;
    }

    span {
        color: ${color.beige};
        font-size: 62px;
        margin-left: 10px;

        @media only screen and (min-width: 992px) and (max-width: 1439px) {
            font-size: 40px;
        }

        @media ${breakpoints.xsmd} {
            font-size: 31px;
        }
    }
`;

export const Timeline = styled.div`
    text-align: right;
    font-size: 30px;

    @media only screen and (min-width: 992px) and (max-width: 1439px) {
        font-size: 24px;
    }

    @media ${breakpoints.xsmd} {
        font-size: 15px;
    }
`;
