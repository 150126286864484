import React from 'react';
import { CollectionContainer, CollectionContent, Title, SliderLoop, ItemList, Item } from './styles';

const Collection = () => {

  const IMG_LIST = [];
  const IMG_LIST2 = [];

  for (let i = 1; i < 25; i++) {
    const imgObj = {
      src: '/images/m-' + i + '.png',
      id: i,
    };
    IMG_LIST.push(imgObj);
  }

  for (let i = 25; i < 49; i++) {
    const imgObj = {
      src: '/images/m-' + i + '.png',
      id: i,
    };
    IMG_LIST2.push(imgObj);
  }

  return (
    <CollectionContainer id="collection">

      <CollectionContent className='main-container'>
        <Title className='title'>THE COLLECTION</Title>
        <p>
          5,000 MohawkNFTs have been programmatically generated from a pool of unique attributes and traits.
          <br></br><br></br>
          Once minted onto the Ethereum blockchain, each MohawkNFT can capture one Mohawk Cyborg. Having one gives you unrestricted access to the augmentation room!
          <br></br><br></br>
          In the augmentation room, owners can use their captured Mohawk Cyborgs to receive exclusive benefits, collaborations, merchandise, experiences, and NFT drops.
          <br></br><br></br>
          The augmentation room is currently undergoing repair and will open soon!
        </p>
      </CollectionContent>

      <SliderLoop>
        <ItemList>
          {
            IMG_LIST.map((img) => {
                return (
                  <Item key={img.id}>
                    <img src={img.src} alt='' />
                  </Item>
                );
            })
          }
          {
            IMG_LIST.map((img) => {
                return (
                  <Item key={img.id} className='hide--desktopOnly'>
                    <img src={img.src} alt='' />
                  </Item>
                );
            })
          }
        </ItemList>

        <ItemList className='bottom'>
          {
            IMG_LIST2.map((img) => {
                return (
                  <Item key={img.id}>
                    <img src={img.src} alt='' />
                  </Item>
                );
            })
          }
          {
            IMG_LIST2.map((img) => {
                return (
                  <Item className='hide--desktopOnly' key={img.id}>
                    <img src={img.src} alt='' />
                  </Item>
                );
            })
          }
        </ItemList>
      </SliderLoop>
      
    </CollectionContainer>
  );
};

export default Collection;