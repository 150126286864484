import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const Modal = styled.div`
    width: 100vw;
    height: 100vh;
    background: #282B2ECC;
    position: fixed;
    z-index: 999;
    display: none;
`;

export const ModalContent = styled.div`
    position: absolute;
    z-index: 999;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 90vh;
    height: 90vh;
    max-width: 1000px;
    max-height: 1000px;
    background: ${color.red};
    border: 16px solid ${color.bgColor};
    border-radius: 100px;
    font-weight: 600;
    font-size: 46px;
    padding: 4vh 9vh;

    display: flex;
    align-items: center;
    justify-content: center;

    @media ${breakpoints.xsmd} {
        width: 90vw;
        height: 90vw;
    }

    @media ${breakpoints.xs} {
        border-radius: 50px;
        padding: 4vh 4vh;
        border: 6px solid ${color.bgColor};
    }

    img {
        width: 13vw;
        max-width: 150px;

        @media ${breakpoints.xsmd} {
            width: 13vw;
        }

        @media ${breakpoints.xs} {
            width: 13vw;
        }
    }

    p {
        text-align: center;
        font-size: 70px;
        margin: 0 0 0 60px;

        @media only screen and (max-width: 1300px) {
            font-size: 50px;
        }

        @media ${breakpoints.xs} {
            font-size: 24px;
            margin: 0;
        }
    }
`;

export const BtnClose = styled.button`
    cursor: pointer;
    background: ${color.beige};
    color: ${color.bgColor};
    border: 5px solid ${color.bgColor};
    border-radius: 50%;
    width: 80px;
    height: 80px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 43px;
    font-weight: bold;

    @media ${breakpoints.xs} {
        width: 50px;
        height: 50px;
        font-size: 23px;
    }
`;