import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const Modal = styled.div`
    width: 100vw;
    height: 100vh;
    background: #282B2ECC;
    position: fixed;
    z-index: 999;
    display: none;
`;

export const ModalContent = styled.div`
    position: absolute;
    z-index: 999;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 90vh;
    height: 90vh;
    max-width: 1000px;
    max-height: 1000px;
    background: ${color.red};
    border: 16px solid ${color.bgColor};
    border-radius: 100px;
    font-weight: 600;
    font-size: 46px;
    padding: 4vh 9vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media ${breakpoints.xsmd} {
        width: 90vw;
        height: 90vw;
    }

    @media ${breakpoints.xs} {
        border-radius: 50px;
    }

    .loading-wrapper {
        display: flex;
        width: 45vh;
        max-width: 500px;
        position: relative;

        @media ${breakpoints.xsmd} {
            width: 30vh;
        }

        @media ${breakpoints.xs} {
            width: 38vw;
        }
    }

    .symbol {
        width: 30vh;
        max-width: 300px;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @media ${breakpoints.xsmd} {
            width: 20vh;
        }

        @media ${breakpoints.xs} {
            width: 25vw;
        }
    }

    .loading-icon {
        animation: rotating 3s linear infinite;
        width: 100%;
    }

    p {
        text-align: center;
        font-size: 70px;
        margin: 40px 0 0;

        @media only screen and (max-width: 1300px) {
            font-size: 50px;
        }

        @media ${breakpoints.xs} {
            font-size: 24px;
            margin: 20px 0 0;
        }
    }

    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

export const LoadingWrapper = styled.div`

`;