import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';
import { SocialList } from '../../common/Social/styles'

export const FollowUsContainer = styled.section`
    width: 100%;
    padding-top: 160px;

    @media ${breakpoints.md} {
        padding-top: 1vw;
    }

    @media ${breakpoints.xs} {
        padding-top: 45px;
    }

    p {
        font-size: 30px;
        margin: 40px 0;

        @media ${breakpoints.xsmd} {
            font-size: 18px;
            margin: 20px 0 25px;
        }
    }

    img {
        @media only screen and (min-width: 992px) and (max-width: 1680px) {
            width: 30vw;
        }

        @media ${breakpoints.md} {
            width: 30vw;
        }

        @media ${breakpoints.xs} {
            width: 100%;
        }
    }
`;

export const Title = styled.h2`
    font-size: 104px;
    line-height: 0.7;
    color: ${color.red};

    @media ${breakpoints.xsmd} {
        font-size: 40px;
    }
`;

export const FollowUsContent = styled.div`
    padding-bottom: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${breakpoints.xsmd} {
        padding-bottom: 3vw;
    }

    @media ${breakpoints.xs} {
        flex-direction: column;
        align-items: flex-start;
    }
    
    
    ${SocialList} {
        display: flex;

        .btn-guide {
            display: none;
        }

        li {
            padding: 0 34px 0 0;

            @media ${breakpoints.xsmd} {
                padding: 0 15px 0 0;
            }
        }

        a {
            display: block;
        }

        svg {
            width: 65px;

            @media ${breakpoints.md} {
                width: 4vw;
            }

            @media ${breakpoints.xs} {
                width: 45px;
            }
        }
    }
`;